import * as LiveUpdates from "@capacitor/live-updates";
import { captureException } from "@sentry/vue";
import { isLocalEnv, isNativePlatform } from "@/application/utils/envInfo";
import { ref } from "vue";

const isLiveUpdateEnabled = isNativePlatform && !isLocalEnv;
export const updateProgress = ref(0);

export const liveUpdateConfig = (channel: string): Promise<void> => {
  return LiveUpdates.setConfig({ channel });
}

export const liveUpdateSyncAndReload = async () => {
  if (!isLiveUpdateEnabled) return;

  try {
    const result = await LiveUpdates.sync((progrss) => updateProgress.value = Math.floor(progrss*100));
    if (result.activeApplicationPathChanged) {
      await LiveUpdates.reload();
    }
    updateProgress.value = 0;
  } catch (error: unknown) {
    captureException(error);
  }
};
