import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';

export const platform = Capacitor.getPlatform();
export const isAndroid = platform === 'android';
export const isIos = platform === 'ios';
export const isNativePlatform = Capacitor.isNativePlatform();
export const isWebPlatform = !isNativePlatform;
export const appEnv = import.meta.env.VITE_APP_ENV;
export const viteMode = import.meta.env.MODE;
export const isLocalEnv = import.meta.env.VITE_APP_ENV === 'local';
export const isStagingEnv = import.meta.env.VITE_APP_ENV === 'staging';
export const isProductionEnv = import.meta.env.VITE_APP_ENV === 'production';
export const isDevelopmentBuild = import.meta.env.DEV;
export const isProductionBuild = import.meta.env.PROD;
export const appUrl = import.meta.env.VITE_APP_URL;
export const buildNumber = isIos
  ? import.meta.env.VITE_IOS_BUILD
  : isAndroid
    ? import.meta.env.VITE_ANDROID_BUILD
    : 0;
export const commit = import.meta.env.VITE_COMMIT;
export const shortCommit = commit.slice(0,7);
export const version = import.meta.env.VITE_APP_VERSION;
export const marketingVersion = isIos
  ? import.meta.env.VITE_IOS_MARKETING
  : isAndroid
    ? import.meta.env.VITE_ANDROID_MARKETING
    : version;

export const versionString = `${marketingVersion} (${buildNumber}.${shortCommit})`;

export async function getDeviceName() {
  const info = await Device.getInfo();

  if (info.name) return info.name;

  return isNativePlatform ? 'Native Mobile Device' : 'Web Device';
}

export async function onNative(callback: () => void|Promise<void>) {
  if (isNativePlatform) await callback();
}
