<script setup lang="ts">
import { onBeforeUnmount, onMounted, provide, ref } from 'vue';
import { DsNotification } from "@demvsystems/design-components";
import { useScroll } from '@vueuse/core';
import UpdateOverlay from "@/application/components/UpdateOverlay.vue";

const isOnline = ref(true)
const onOnline = () => {
  isOnline.value = true
}

const onOffline = () => {
  isOnline.value = false
}

onMounted(() => {
  window.addEventListener('online', onOnline);
  window.addEventListener('offline', onOffline);
});

onBeforeUnmount(() => {
  window.removeEventListener('online', onOnline);
  window.addEventListener('offline', onOffline);
})

const main = ref<HTMLElement | null>(null);

const { y } = useScroll(main, { throttle: 50 });

provide('y', y);
</script>

<template>
  <header id="app-header" class="app-head"></header>
  <main ref="main" id="app-main">
    <div id="app-page-head"></div>
    <div id="app-page-content">
      <router-view />
    </div>
    <div id="app-page-footer"></div>
  </main>
  <footer id="app-footer"></footer>
  <DsNotification target="#app-notifications" class="p-safe notification-fix" />
  <Teleport to="#app-overlay">
    <UpdateOverlay />
  </Teleport>
</template>

<style>
html, body {
  height: 100dvh;
  min-height: 100dvh;
  overflow: hidden;
  margin: 0;
}

#app {
  height: calc(100dvh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
  min-height: calc(100dvh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
  margin-top: env(safe-area-inset-top);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

#app-main {
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;
}

#app-page-content {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
}

#app-overlay {
  width: 100%;
  max-height: 100vh;
  min-height: 100vh;
  position: absolute;
  overflow: hidden;
  pointer-events: none;
  z-index: 999;
}

#app-notifications {
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
  position: absolute;
  pointer-events: none;
}

.p-safe {
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

.notification-fix {
  bottom: auto !important;
  top: 1rem !important;
}
</style>
