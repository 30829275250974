import { Finanzmanager } from "@/application/router/types/types";
import type { RouteRecordRaw } from "vue-router";
import LoginPage from "@/authentication/pages/LoginPage.vue";
import MainLayout from "../layouts/MainLayout.vue";
import LogoutPage from '@/authentication/pages/LogoutPage.vue';
import { useUserStore } from "@/user/store/userStore";
import { logout as logoutApi } from "@/api/auth/authApi";
import SparseLayout from "@/application/layouts/SparseLayout.vue";
import IosInstallationPage from "@/application/components/IosInstallationPage.vue";

export const publicRoutes: RouteRecordRaw[] = [
  {
    path: '/',
    redirect: { name: Finanzmanager.VERTRAEGE },
  },
  {
    path: '/',
    component: MainLayout,
    children: [
      {
        path: 'datenschutz',
        name: Finanzmanager.DATENSCHUTZ,
        component: () => import("@/legal/pages/PrivacyPage.vue"),
        meta: {
          public: true,
          title: 'Datenschutz',
          scrollTop: true,
        },
      },
      {
        path: 'impressum',
        name: Finanzmanager.IMPRESSUM,
        component: () => import("@/legal/pages/ImprintPage.vue"),
        meta: {
          public: true,
          title: 'Impressum',
          scrollTop: true,
        },
      },
      {
        path: '/ios-installation',
        component: IosInstallationPage,
        meta: {
          public: true,
          scrollTop: true,
        },
      }
    ]
  },
  {
    path: '/',
    component: SparseLayout,
    children: [
      {
        name: Finanzmanager.LOGIN,
        path: 'login',
        component: LoginPage,
        meta: { public: true }
      },
      {
        name: Finanzmanager.LOGOUT,
        path: 'logout',
        component: LogoutPage,
        beforeEnter: async () => {
          await logoutApi();
          useUserStore().onLoggedOut();
        },
        meta: {
          public: true,
        },
      },
      {
        name: Finanzmanager.INTERESSENT,
        path: '/interessent/:maklerOrAgency/:uid',
        props: true,
        component: () => import("@/kundenzugang/pages/InteressentPage.vue"),
        meta: {
          public: true,
        }
      },
      {
        name: Finanzmanager.ANMELDEN,
        path: '/anmelden/:maklerOrAgency/:uid',
        props: true,
        meta: {
          public: true,
        },
        component: () => import("@/kundenzugang/pages/SignUpPage.vue"),
      },
    ],
  },
];
