import type { Pinia, Store } from 'pinia';
import { defineStore, getActivePinia } from 'pinia';
import { useBaseDataStore } from "@/user/store/baseDataStore";
import { useUnreadHintStore } from "@/application/store/unreadHintStore";
import { isAuthenticated as isAuthenticatedApi } from "@/api/auth/authApi";
import { call } from "@/api/lib/integration";
import { getSecret, removeSecret, SecretNames } from "@/application/utils/secretStore";
import { resetErrors } from "@/checkup/state";
import { isNativePlatform, isProductionEnv } from '@/application/utils/envInfo';
import { ref } from "vue";

interface ExtendedPinia extends Pinia {
  _s: Map<string, Store>;
}

export const useUserStore = defineStore('userStore', () => {
  const isAuthenticated = ref<null|boolean>(null);

  async function checkAuth() {
    if (isNativePlatform) {
      const [ac, cs] = await Promise.all([
        getSecret(SecretNames.AccessToken),
        getSecret(SecretNames.ClientSecret)
      ]);

      if (ac === null || cs === null) {
        isAuthenticated.value = false;
        onLoggedOut();
        return isAuthenticated.value;
      }
    }

    await call(
      isAuthenticatedApi(),
      (value) => { isAuthenticated.value = value},
      () => { isAuthenticated.value = false },
    );

    if (isAuthenticated.value) {
      await onLoggedIn();
    } else {
      onLoggedOut();
    }

    return isAuthenticated.value;
  }

  function $reset() {
    // isAuthenticated.value = null;
  }

  async function onLoggedOut () {
    resetErrors();
    await Promise.all([
      removeSecret(SecretNames.AccessToken),
      (SecretNames.ClientSecret)
    ]);

    if (isProductionEnv) {
      window._paq.push(['resetUserId']);
    }

    isAuthenticated.value = false

    const activePinia = getActivePinia() as ExtendedPinia;
    activePinia?._s.forEach(store => store.$reset());
  }

  async function onLoggedIn () {
    const activePinia = getActivePinia() as ExtendedPinia;
    activePinia?._s.forEach(store => store.$reset());

    isAuthenticated.value = true
    const p = useBaseDataStore().fetchBaseData();
    useUnreadHintStore().fetchUnreadHints().then();
    await p;

    return;
  }

  return { onLoggedIn, onLoggedOut, checkAuth, isAuthenticated, $reset };
});
