<template>
  <div
    v-if="!finished"
    class="flex flex-col"
  >
    <div class="-mx-4 sm:-mx-6 -mt-4 sm:-mt-6">
      <h3 class="text-lg text-left font-bold text-gray-900 pb-4 px-4 sm:px-6 pt-4 sm:pt-6 shadow mb-0">
        Neuen Vertrag anlegen
      </h3>
    </div>
    <div class="-mx-4 sm:-mx-6 overflow-y-auto pt-3">
      <div class="flex flex-col gap-4 px-4 sm:px-6 py-2">
        <DsFormGroup
          label="Gesellschaft"
          :inline-reverse="!isMobile"
        >
          <div class="w-full flex justify-end">
            <DsSelect
              v-model="contractData.gesellschaft_id"
              :data="gesellschaftOptions"
              placeholder=""
              size="lg"
              class="w-full md:w-80"
            />
          </div>
          <DsFieldError> {{ validationErrors.gesellschaft_id }}</DsFieldError>
        </DsFormGroup>

        <DsFormGroup
          label="Sparte"
          :inline-reverse="!isMobile"
        >
          <div class="w-full flex justify-end">
            <DsSelect
              v-model="contractData.sparte_id"
              :data="sparten"
              value-key="id"
              label-key="display_name"
              placeholder=""
              size="lg"
              class="w-full md:w-80"
            >
              <template #entry="{ entry }">
                {{ entry.display_name }}
                <span
                  v-if="!!entry.type"
                  class="badge text-secondary"
                >
                {{ entry.type }}
              </span>
              </template>
            </DsSelect>
          </div>
          <DsFieldError> {{ validationErrors.sparte_id }}</DsFieldError>
        </DsFormGroup>

        <DsFormGroup
          label="Vertrags-Nr."
          :inline-reverse="!isMobile"
          class="whitespace-nowrap"
        >
          <div class="w-full flex justify-end">
            <DsInput
              v-model="contractData.vertragsnummer"
              placeholder=""
              size="lg"
              class="w-full md:w-80"
            />
          </div>
          <DsFieldError> {{ validationErrors.vertragsnummer }}</DsFieldError>
        </DsFormGroup>

        <DsFormGroup
          label="Beginn"
          :inline-reverse="!isMobile"
        >
          <div class="w-full flex justify-end">
            <DateInput
              v-model="contractData.beginn"
              placeholder=""
              size="lg"
              class="w-full md:w-80"
            />
          </div>
          <DsFieldError> {{ validationErrors.beginn }}</DsFieldError>
        </DsFormGroup>

        <DsFormGroup
          label="Ablaufdatum"
          :inline-reverse="!isMobile"
        >
          <div class="w-full flex justify-end">
            <DateInput
              v-model="contractData.ablaufdatum"
              size="lg"
              class="w-full md:w-80"
            />
          </div>
          <DsFieldError> {{ validationErrors.ablaufdatum }}</DsFieldError>
        </DsFormGroup>

        <DsFormGroup
          label="Beitrag"
          :inline-reverse="!isMobile"
        >
          <div class="w-full flex justify-end">
            <MoneyInput
              v-model="contractData.beitrag"
              size="lg"
              class="w-full md:w-80"
            />
          </div>
          <DsFieldError> {{ validationErrors.beitrag }}</DsFieldError>
        </DsFormGroup>

        <DsFormGroup
          label="Zahlweise"
          :inline-reverse="!isMobile"
        >
          <div class="w-full flex justify-end">
            <DsSelect
              v-model="contractData.zahlweise"
              :data="PaymentTypeOptions"
              placeholder=""
              size="lg"
              class="w-full md:w-80"
            />
          </div>
          <DsFieldError> {{ validationErrors.zahlweise }}</DsFieldError>
        </DsFormGroup>

        <DsFormGroup
          v-if="isKfz"
          label="Kennzeichen"
          :inline-reverse="!isMobile"
        >
          <div class="flex justify-between md:justify-end mx-auto lg:mx-0 w-full gap-1">
            <DsInput
              v-model="contractData.kennzeichen.unterscheidungszeichen"
              placeholder="HH"
              :mask="{
                mask: 'AAA',
                tokens: { A: { pattern: /[a-zA-ZäöüÄÖÜ]/, uppercase: true } },
              }"
              style="max-width: 74px"
            />
            <DsInput
              v-model="contractData.kennzeichen.erkennungszeichen"
              placeholder="XY"
              mask="AA"
              style="max-width: 56px"
            />
            <DsInput
              v-model="contractData.kennzeichen.erkennungsnummer"
              placeholder="1234"
              mask="####"
              style="max-width: 78px"
            />
            <DsSelect
              v-model="contractData.kennzeichen.abschliessendeszeichen"
              :data="[
                {label: 'H', value: 'H'},
                {label: 'E', value: 'E'},
              ]"
              :searchable="false"
              style="min-width: 100px"
              placeholder=""
            />
          </div>
        </DsFormGroup>

        <DsFormGroup
          label="Notiz für den Makler"
          :inline-reverse="!isMobile"
        >
          <DsTextarea
            v-model="contractData.notiz"
            placeholder="Wenn Sie möchten, können Sie eine Notiz zur Vertragsanlage hinterlassen..."
            :rows="3"
            class="w-full md:w-80"
          />
        </DsFormGroup>

        <div class="flex flex-row justify-center md:justify-end md:mr-12 mt-2">
          <DsButton
            :icon="addDocuments ? 'chevron-up' : 'chevron-down'"
            variant="clear"
            size="sm"
            @click="addDocuments = !addDocuments"
          >
            Dokument/-e hinzufügen
          </DsButton>
        </div>

        <div v-if="addDocuments" class="mb-5 space-y-4">
          <DsFormGroup
            label="Dokument/-e"
            :inline-reverse="!isMobile"
            class="whitespace-nowrap"
          >
            <div class="w-full flex flex-col items-end">
              <FileInput
                :accept="validFileTypes.join(', ')"
                class="mt-2.5 w-full md:w-80 whitespace-normal"
                @input="setFiles"
              >
                <DocTypesHint />
              </FileInput>

              <InvalidFilesHint
                :files="invalidFiles"
                :error-messages="errorMessages"
              />

              <DocumentList
                :files="validFiles"
                :file-names="fileNames"
                :disable-image-inputs="combine"
                class="mt-2.5 w-full md:w-80"
                @set-file-name="setFileName"
                @remove="remove"
              />

              <DsFormGroup
                v-if="hasMultipleImageFiles"
                label="Bilder zu einer PDF-Datei kombinieren"
                label-for="combine-images-check"
                class="ml-8 mt-1 mr-auto"
                inline
              >
                <DsCheckbox
                  id="combine-images-check"
                  v-model="combine"
                  :disabled="exceedsImageCombinationLimit"
                />
              </DsFormGroup>

              <ImageCombinationHint
                v-if="exceedsImageCombinationLimit"
                class="ml-1.5 mt-2.5"
              />
            </div>
          </DsFormGroup>

          <DsFormGroup
            label="Typ"
            :inline-reverse="!isMobile"
          >
            <div class="w-full flex justify-end">
              <DsSelect
                v-model="documentType"
                :data="documentTypeOptions"
                placeholder=""
                size="lg"
                class="w-full md:w-80"
              />
            </div>
          </DsFormGroup>
        </div>
      </div>

      <div
        v-if="documentsLoading"
        class="text-muted"
      >
        <span class="spinner-border spinner-border-sm mr-1.5" />
        {{ validFiles.length > 1 ? 'Dokumente werden' : 'Dokument wird' }} hochgeladen...
      </div>

      <div
        v-if="hasError"
        class="my-2.5 text-danger text-center"
      >
        Es gab einen Fehler beim Anlegen des Vertrags
      </div>
    </div>

    <div class="flex flex-row justify-center sm:justify-end gap-3 mt-3">
      <DsButton
        variant="secondary"
        class="w-full sm:w-auto"
        size="lg"
        @click="() => {
          $emit('close')
          clear()
        }"
      >
        Abbrechen
      </DsButton>
      <DsButton
        variant="primary"
        class="w-full sm:w-auto"
        size="lg"
        :handler="uploadHandler"
      >
        Vertrag anlegen
      </DsButton>
    </div>
  </div>

  <div
    v-else
    class="flex flex-col items-center"
  >
    <div
      class="flex flex-col items-center alert w-full text-center mb-0"
      :class="hasUploadError ? 'alert-warning' : 'alert-success'"
    >
      <span>Das Anlegen des Vertrags war erfolgreich.</span>

      <span
        v-if="hasUploadError"
        class="text-danger mt-1.5"
      >Bei dem Hochladen der Dokumente gab es jedoch einen Fehler</span>
    </div>

    <span class="text-sm text-center my-2.5">
      Damit zukünftig die Informationen zu
      diesem Vertrag automatisch übermittelt werden, wird sich Ihr Makler bald nochmal
      bei Ihnen melden.
    </span>

    <div class="flex flex-col-reverse md:flex-row justify-end w-full gap-3 mt-5">
      <DsButton
        variant="secondary"
        size="lg"
        @click="() => {
          $emit('close')
          clear()
        }"
      >
        Schließen
      </DsButton>
      <DsButton
        variant="primary"
        size="lg"
        @click="finished = false"
      >
        Weiteren Vertrag anlegen
      </DsButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  DsButton,
  DsCheckbox,
  DsFieldError,
  DsFormGroup,
  DsInput,
  DsSelect,
  DsTextarea
} from '@demvsystems/design-components';
import { captureException } from '@sentry/vue';
import { computed, reactive, ref, watch } from 'vue';

import { createContract } from '@/api/contracts/contractsApi';
import DateInput from '@/application/components/form-elements/DateInput/DateInput.vue';
import MoneyInput from '@/application/components/form-elements/MoneyInput/MoneyInput.vue';
import { useGesellschaften } from '@/application/composables/gesellschaften';
import { useSparten } from '@/documents/composables/sparten';
import { toOptions } from '@/application/composables/vselect';
import { unflatten } from '@/checkup/utils/unflatten';
import type { ContractData } from '@/contracts/types/types';
import { PaymentTypeOptions } from '@/contracts/types/types';
import DocTypesHint from '@/upload/components/DocTypesHint.vue';
import FileInput from '@/upload/components/FileInput.vue';
import DocumentList from '@/upload/components/FileList.vue';
import ImageCombinationHint from '@/upload/components/ImageCombinationHint.vue';
import InvalidFilesHint from '@/upload/components/InvalidFilesHint.vue';
import { useContractStore } from '@/contracts/stores/contractStore';
import { trackEvent } from '@/application/utils/matomo';
import { TrackEventCategory } from '@/application/utils/matomo/events';
import { checkForDigits, checkForLetters, toUpperCase } from '@/application/utils/strings';
import { useDetectMobile } from "@/application/composables/detectMobile";
import { useDocumentUpload } from "@/documents/composables/documentUpload";
import { useDocumentTypes } from "@/documents/composables/documentTypes";
import { call } from "@/api/lib/integration";
import { ValidationError } from "@/api/lib/errors";

defineEmits(['close']);

enum KFZ {
  PRIVATE_SACHVERSICHERUNG = 171,
  PRIVATE_SACHVERSICHERUNG_KFZ_ANHAENGER = 310,
  GEWERBLICHE_SACHVERSICHERUNG = 179,
  GEWERBLICHE_SACHVERSICHERUNG_KFZ_ANHAENGER = 409,
  GEWERBLICHE_SACHVERSICHERUNG_KFZ_HANDEL_HANDWERK = 506,
}


const getInitContractData = (): ContractData => ({
  sparte_id: null,
  gesellschaft_id: null,
  vertragsnummer: null,
  beginn: null,
  ablaufdatum: null,
  beitrag: null,
  zahlweise: null,
  kennzeichen: {
    unterscheidungszeichen: null,
    erkennungszeichen: null,
    erkennungsnummer: null,
    abschliessendeszeichen: null,
  },
  notiz: null,
});


const kfzSparten = new Set([
  KFZ.PRIVATE_SACHVERSICHERUNG,
  KFZ.PRIVATE_SACHVERSICHERUNG_KFZ_ANHAENGER,
  KFZ.GEWERBLICHE_SACHVERSICHERUNG,
  KFZ.GEWERBLICHE_SACHVERSICHERUNG_KFZ_ANHAENGER,
  KFZ.GEWERBLICHE_SACHVERSICHERUNG_KFZ_HANDEL_HANDWERK,
]);

const loading = ref(false);
const hasError = ref(false);
const hasUploadError = ref(false);
const finished = ref(false);
const addDocuments = ref(false);
const combine = ref(false);
const contractData = reactive<ContractData>(getInitContractData());
const validationErrors = reactive(getInitContractData()); // TODO add correct type
const documentType = ref<number | null>(null);
const { fetchContractsAndDepots } = useContractStore();
const { sparten, fetchSparten } = useSparten();
const { gesellschaften, fetchGesellschaften } = useGesellschaften();
const {
  validFileTypes,
  validFiles,
  invalidFiles,
  fileNames,
  hasMultipleImageFiles,
  exceedsImageCombinationLimit,
  errorMessages,
  setFiles,
  setFileName,
  remove,
  upload,
  loading: documentsLoading,
} = useDocumentUpload({ compressImages: true });
const { documentTypes, fetchDocumentTypes } = useDocumentTypes();

const { isMobile } = useDetectMobile();

const gesellschaftOptions = toOptions(gesellschaften, 'name', 'id');
const documentTypeOptions = toOptions(documentTypes, 'name', 'id');

const isKfz = computed(() => contractData.sparte_id && kfzSparten.has(contractData.sparte_id));

watch(() => contractData.kennzeichen.unterscheidungszeichen, (value) => {
  contractData.kennzeichen.unterscheidungszeichen = toUpperCase(checkForLetters(value));
}, { flush: 'post' });

watch(() => contractData.kennzeichen.erkennungszeichen, (value) => {
  contractData.kennzeichen.erkennungszeichen = toUpperCase(checkForLetters(value));
}, { flush: 'post' });

watch(() => contractData.kennzeichen.erkennungsnummer, (value, oldValue) => {
  if (!value) return;
  const output = checkForDigits(value.toString());
  if (output !== null) {
    contractData.kennzeichen.erkennungsnummer = Number.parseInt(output, 10);
  } else if (oldValue !== null && Number.isInteger(oldValue)) {
    contractData.kennzeichen.erkennungsnummer = oldValue;
  } else {
    contractData.kennzeichen.erkennungsnummer = null;
  }
}, { flush: 'post' });

watch([hasMultipleImageFiles, exceedsImageCombinationLimit], () => {
  combine.value = hasMultipleImageFiles.value && !exceedsImageCombinationLimit.value;
});

watch(
  combine,
  () => validFiles.value.forEach((file, index) => {
    if (file.type.startsWith('image/')) {
      setFileName(file.name, index);
    }
  }),
);

const clear = () => {
  // contract related
  Object.assign(contractData, getInitContractData());
  Object.assign(validationErrors, getInitContractData());
  hasError.value = false;
  // upload related
  documentType.value = null;
  addDocuments.value = false;
  invalidFiles.value = [];
  validFiles.value = [];
};

const uploadDocumentsForContract = async (contractId: number) => {
  hasUploadError.value = false;

  if (!(addDocuments.value && validFiles.value.length > 0)) return;

  try {
    await upload({
      contract_id: contractId,
      insurance_company_id: contractData.gesellschaft_id,
      product_combo_id: contractData.sparte_id,
      document_type_id: documentType.value,
      note: null,
      combine: combine.value,
    });
  } catch (error) {
    // We don't want an errorneous upload to fail the contract creation process
    // so we pass here. When an upload fails we render a message to the user
    hasUploadError.value = true;
    captureException(error);
  }
};

const create = async () => {
  hasError.value = false;
  loading.value = true;
  Object.assign(validationErrors, getInitContractData());
  await call(
    createContract(contractData),
    async (data) => {
      await uploadDocumentsForContract(data.contract_id);
      clear();
      await fetchContractsAndDepots(true);
      finished.value = true;
    },
    (error) => {
      if (error instanceof ValidationError) {
        Object.assign(validationErrors, unflatten(error.errors));
      } else {
        hasError.value = true;
      }
    },
  );
  loading.value = false;
};

const uploadHandler = async () => {
  await create();
  trackEvent({ category: TrackEventCategory.DOCUMENT_UPLOAD, action: 'Hochladen', name: 'click' });
};

fetchGesellschaften();
fetchSparten();
fetchDocumentTypes();
</script>
