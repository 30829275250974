import { useUserStore } from "@/user/store/userStore";
import type { RouteLocationNormalized, RouteLocationRaw } from "vue-router";
import { Finanzmanager } from "@/application/router/types/types";

export async function authenticateGuard(to: RouteLocationNormalized) {
  if (useUserStore().isAuthenticated === null) {
    await useUserStore().checkAuth();
  }

  if (to.query?.requestAccessToken) {
    return to.name === Finanzmanager.LOGIN
      ? true
      : loginRedirect(to);
  }

  if (useUserStore().isAuthenticated) {
    return to.name === Finanzmanager.LOGIN
      ? { name: Finanzmanager.VERTRAEGE }
      : true;
  }

  if (to.meta.public) return true;

  return loginRedirect(to);
}

function loginRedirect(to: RouteLocationNormalized): RouteLocationRaw {
  const { requestAccessToken = undefined, ...query } = { ...to.query };

  const route = Object.keys(query).length > 0
    ? `${to.path}?${new URLSearchParams(query)}`
    : `${to.path}`;

  return {
    name: Finanzmanager.LOGIN,
    query: { redirect: encodeURIComponent(route), requestAccessToken },
  };
}
