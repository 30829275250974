<template>

  <div class="relative text-center" v-if="preflightLoading">
    <DsIcon class="mr-2 text-blue-700" name="spinner-third" variant="duotone" spin size="2x"/>
  </div>

  <div  v-else-if="errorMsg">
  <DsAlert type="error" class="mb-5">{{ errorMsg }}</DsAlert>
    <DsButton
      variant="secondary"
      size="lg"
      type="submit"
      @click="emit('switch-to-password')"
      class="mt-6 w-full"
    >
      zum Login
    </DsButton>
  </div>

  <div v-else-if="hasTokenErrors" class="relative text-center">
    <DsAlert type="error" :label="errors.requestAccessToken[0]" />
    <DsButton
      variant="secondary"
      size="lg"
      type="submit"
      @click="emit('switch-to-password')"
      class="mt-6 w-full"
    >
      zum Login
    </DsButton>
  </div>

  <DsForm
    v-if="twoFactorMode && !hasTokenErrors && !preflightLoading"
    :validation-errors="errors"
    class="flex flex-col gap-2"
    @submit.prevent
  >
    <div>
       Für Ihre Anmeldung ist ein weiterer Faktor notwendig. {{ twoFactorLabels[twoFactorMode] }}
    </div>

    <DsFormGroup validation-name="answer" :label="twoFactorMode === TwoFactors.Birthdate ? 'Geburtsdatum' : 'Code/Recoverycode' ">
      <DsInput
        v-model="form.answer"
        type="default"
        size="lg"
        immediate
        autofocus
        :placeholder="twoFactorMode === TwoFactors.Birthdate? 'tt.mm.jjjj' : ''"
      />
    </DsFormGroup>

    <DsButton
      variant="primary"
      size="lg"
      type="submit"
      :handler="submit"
      class="mt-3 w-full"
    >
      Anmelden
    </DsButton>
  </DsForm>
</template>

<script setup lang="ts">
import { DsAlert, DsButton, DsForm, DsFormGroup, DsIcon, DsInput } from '@demvsystems/design-components';
import { useForm } from "@/api/lib/integration";
import { requestAccessTokenLogin } from "@/api/auth/authApi";
import { computed, onMounted, ref } from "vue";
import { getDeviceName } from "@/application/utils/envInfo";
import { TwoFactors } from '@/api/auth/types';

// 1.1 token is invalid, check if old login exists
// 1.2 token is valid, check 2fa
// 2. logging in

interface Props {
  requestAccessToken: string,
}

const props = defineProps<Props>();

const twoFactorLabels = {
  [TwoFactors.Fortitfy]: 'Bitte geben Sie Ihren Authenticator Code ein.',
  [TwoFactors.Birthdate]: 'Bitte bestätigen Sie Ihr Geburtsdatum.',
}

const emit = defineEmits(['logging-in', 'switch-to-password']);

const twoFactorMode = ref('');
const preflightLoading = ref(false);

const { errors, form, submit, errorMsg } = useForm(
  requestAccessTokenLogin,
  {
    requestAccessToken: props.requestAccessToken,
    answer: undefined as string|undefined,
    deviceName: '',
  },
    (data) => {
      if (data.twoFactor) {
        twoFactorMode.value = data.twoFactor;
        return;
      }

      emit('logging-in', data.accessToken, data.clientSecret);
    },
);

const hasTokenErrors = computed(() => errors.value.requestAccessToken?.length > 0);


onMounted(async () => {
  form.value.deviceName = await getDeviceName();
  // initially send requestAccessToken to get 2FA challenge or login with accessToken
  preflightLoading.value = true;
  await submit();
  // enable tfa iput validation
  form.value.answer = '';
  preflightLoading.value = false;
});

defineExpose({ form, errors })
</script>
