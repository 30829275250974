<script setup lang="ts">
import { updateProgress } from "@/application/utils/liveUpdate";
import { DsIcon } from "@demvsystems/design-components";
import { ref, watch } from "vue";

let timer;
const show = ref(false);

watch(updateProgress, (value, oldValue) => {
  if (oldValue == null) return;

  if (value !== 0 && value > oldValue) {
    show.value = true;
    clearInterval(timer);
  }

  if (value === 0 && value < oldValue) {
    timer = setTimeout(
      () => { show.value = false; },
      500,
    );
  }
});
</script>

<template>
  <Transition name="fade">
    <div
      v-if="show"
      class="h-dvh flex justify-center align-items-center bg-gray-100 bg-opacity-80 backdrop-blur-xl"
    >
      <div class="w-36 text-gray-700 flex align-items-center">
        <DsIcon
          class="mr-2"
          name="spinner-third"
          variant="duotone"
          spin
          size="lg"
        />
        Update {{ updateProgress }}%
      </div>
    </div>
  </Transition>
</template>
