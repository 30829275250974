import type { RouteLocationNormalized } from 'vue-router';
import { useBaseDataStore } from '@/user/store/baseDataStore';
import { isProductionEnv, platform } from "@/application/utils/envInfo";

function anonymizeUrl(to: RouteLocationNormalized) {
  const last = to.matched.slice(-1)[0];

  return `${last?.path ?? ''}${to.query.redirect ? '?redirect' : ''}`;
}

export async function routeTrackingGuard(to: RouteLocationNormalized, from: RouteLocationNormalized) {
  if (!isProductionEnv) return true;

  const { hashedUserId } = useBaseDataStore();

  // User ID has to be set before each pageview: https://developer.matomo.org/guides/tracking-javascript-guide#user-id
  // same applies to setCustomUrl and setDocumentTitle
  if (hashedUserId) {
    window._paq.push(['setUserId', hashedUserId]);
  }
  window._paq.push(['setCustomDimension', 1, platform]);
  window._paq.push(['setCustomUrl', anonymizeUrl(to)]);
  window._paq.push(['setDocumentTitle', to.meta?.title ?? 'meine-finanzen.digital']);
  window._paq.push(['setReferrerUrl', anonymizeUrl(from)]);

  window._paq.push(['trackPageView']);

  return true;
}
