import type { ApiError } from "@/api/lib/errors";
import { UnauthorizedError } from "@/api/lib/errors";
import { ExpiredClientSignatureError } from "@/api/lib/errors";
import { makeApiErrorFromError, makeApiErrorFromResponse } from "@/api/lib/errors";
import { getCsrfTokenFromCookies, isPlainObject } from "@/api/lib/utils";
import { cloneDeep } from "lodash";
import type { RequestMethod, RequestOptions } from "@/api/lib/types";
import { appUrl, isNativePlatform, isWebPlatform, platform } from "@/application/utils/envInfo";
import { getSecret, SecretNames } from "@/application/utils/secretStore";
import { hmac } from "@/application/utils/hash";
import { sleep } from "@/application/utils/sleep";
import { getRouter } from "@/api/lib/routerRef";
import { Finanzmanager } from "@/application/router/types/types";

export function replaceUrlSegments<T>(path: string, data: T): [string, Partial<T>] {
  if (!path.includes('{')) return [path, data];

  const dataCopy = cloneDeep(data);
  const url = path.replace(
    /\{\w+\}/ig,
    n => {
      const key = n.slice(1,-1);
      delete dataCopy[key];

      return data[key];
    },
  );

  return [url, dataCopy];
}

export function buildBody<T>(data: T): [BodyInit | null , string | null] {
  if (data == null) return [null, null];
  if (data instanceof FormData) return [data, null];

  return [JSON.stringify(data), 'application/json'];
}

export function buildQuery<T extends Record<string, unknown>>(data?: T): string {
  if (data == null) return '';

  const entries = Object.entries(data);
  if (Object.keys(entries).length === 0) return '';

  return '?' + entries.reduce(
    (acc, [key, value]) => {
      if (isPlainObject(value)) {
        return `${acc}&${key}=${encodeURIComponent(JSON.stringify(value))}`; // json stringify if object
      } else if (Array.isArray(value)) {
        if (value.every((e) => Number.isInteger(e) || typeof e === 'string')) {
          return `${acc}&${value.reduce((ctx, item) => `${ctx}&${key}[]=${item}`, '').substring(1)}`; // comma seperated list if number or string array
        } else {
          return `${acc}&${key}=${encodeURIComponent(JSON.stringify(value))}`; // json stringify if complex array
        }
      } else if (value === false || value === true) {
        return `${acc}&${key}=${value ? '1' : '0'}`; // turn bool to 0 and 1
      } else {
        return `${acc}&${key}=${encodeURIComponent(`${value}`)}`; // fallback value to string
      }
    },
    '',
  ).substring(1);
}

async function getCsrfToken() {
  const csrfToken = getCsrfTokenFromCookies();
  if (!csrfToken) {
    const response = await fetch('/api/csrf-cookie');
    await response.text();
  }
  return csrfToken || getCsrfTokenFromCookies();
}

export async function addAuthHeaders(headers: Record<string, string>) {
  if (isNativePlatform) {
    const accessToken = await getSecret(SecretNames.AccessToken);
    const clientSecret = await getSecret(SecretNames.ClientSecret);
    if (accessToken) headers['Authorization'] = `Bearer ${accessToken}`;
    if (clientSecret) {
      const timestamp = Math.floor(Date.now()/1000);
      const sig = await hmac(clientSecret as string, `${timestamp}`) as string;
      headers['X-Client-Signature'] = `${timestamp}:${sig}`
    }
  }

  if (isWebPlatform) {
    const csrfToken = await getCsrfToken();
    if (csrfToken) headers[csrfToken.length < 45 ? 'X-CSRF-TOKEN' : 'X-XSRF-TOKEN'] = csrfToken;
  }
}

export async function makeRequest<Data = void, Res = void>(method: RequestMethod, path: string, options: RequestOptions, data?: Data, run = 0): Promise<Res|ApiError> {
  try {
    const headers = {
      'Accept': options.accept ?? 'application/json',
      'X-Platform': platform,
    };
    await addAuthHeaders(headers);

    const [url, payload] = replaceUrlSegments(path, data);
    const [body, dataContentType] = method !== 'get' ? buildBody(payload) : [null, null];
    const query = method === 'get' ? buildQuery(payload) : '';

    if (dataContentType) headers['Content-Type'] = dataContentType;

    const response = await fetch(
      `${options?.isAbsoluteUrl ? '' : appUrl ?? ''}${url}${query}`,
      { mode: 'cors', redirect: options?.redirect ?? 'error', credentials: 'include', method, signal: options.signal, headers, body }
    );

    const apiError = await makeApiErrorFromResponse(response);

    if (apiError instanceof ExpiredClientSignatureError && run === 0) {
      await sleep(500);
      return await makeRequest(method, path, options, data, run+1);

    } else if (apiError instanceof UnauthorizedError && !options.preventRedirect) {
      await getRouter()?.push({
        name: Finanzmanager.LOGOUT,
        state: { reason: 'Ihre Sitzung ist abgelaufen.' },
      });
    }

    if (apiError) return apiError;
    if (response.status === 204) return undefined as Res; // no content

    const contentType = response.headers.get('Content-Type');
    if (headers.Accept.includes('json') || contentType?.includes('json')) {
      const data = (await response.json()) as Res;
      options.schema?.parse(data);
      return data;
    } else if (contentType?.includes('text')) {
      return (await response.text()) as Res;
    } else {
      return (await response.blob()) as Res;
    }
    // impl response.arrayBuffer();
  } catch (error) {
    console.log(error);
    return makeApiErrorFromError(error);
  }
}
